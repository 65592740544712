import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import { default as Auth } from './components/AuthService';
import Modal from './components/Modal'

export default class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.user = Auth.getProfile();
    this.state = {
      ridePossible: true,
      riding: false,
      showGreeting: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
      errorMessage: false,
      trip_id: localStorage.getItem("trip_id")
    });

    Promise.all([
      axios.get('https://api.sunrise-sunset.org/json?lat=51.603546&lng=6.919904&date=today&formatted=0'),
      axios.get('https://api.sunrise-sunset.org/json?lat=51.603546&lng=6.919904&date=tomorrow&formatted=0'),
    ])
      // use arrow function to avoid loosing context
      // BTW you don't need to use axios.spread with ES2015 destructuring
      .then(([today, tomorrow]) => {
        this.setState({
          sunriseToday: moment(today.data.results.sunrise),
          sunsetToday: moment(today.data.results.sunset),
          sunriseTomorrow: moment(tomorrow.data.results.sunrise),
          isLoading: false
        });
      });
    
    axios.get('https://kutschenapi.h4.ddnss.org/current-trips-count', {
        auth: {
            username: localStorage.getItem("id_token"),
            password: "unused"
        }
    })
    .then( res => {
        this.setState({
            currentTripsCount: res.data.num_trips
        });
    })
  }

  handleTripStart(carriage_id) {
    const now = moment().tz('Europe/Berlin').format("YYYY-MM-DD HH:mm:ss");
    axios.post('https://kutschenapi.h4.ddnss.org/trip',
      {
        carriage_id: carriage_id,
        entrytime: now
      },
      {
        auth: {
          username: localStorage.getItem("id_token"),
          password: "unused"
        }
      })
      .then( response => {
        localStorage.setItem( "trip_id", response.data.trip );
      })
      .catch( error => {
        console.log(error.message);
        this.setState({errorMessage: true})
      })
    this.setState({
      riding: !this.state.riding,
      showGreeting: true
    })
    setInterval(() => {
      this.setState({ showGreeting: false });
    }, 5000); 
  }

  handleTripEnd() {
    axios.put("https://kutschenapi.h4.ddnss.org/change-trip",
    {
      trip_id: localStorage.getItem("trip_id"),
      exittime: moment().format("YYYY-MM-DD HH:mm:ss")
    },
    {
      auth: {
        username: localStorage.getItem("id_token"),
        password: "unused"
      }
    }
    );
    localStorage.removeItem("trip_id");
    this.setState({
      riding: !this.state.riding
    });
  }

  render() {
    const oneOurAfter = moment(this.state.sunriseToday).subtract(1.5, 'hours');
    const twoHoursBefore = moment(this.state.sunsetToday).add(1.5, 'hours');
    const sunriseTomorrow = moment(this.state.sunriseTomorrow).subtract(1.5, 'hours').format("DD.MM.YY HH:mm");
    
    return (
      <div>
        {this.state.isLoading 
        ? 
          <div className="center">
            <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>
        :
        <div className="container mt-5">
          <h4>Willkommen, {localStorage.getItem("username")}</h4>
          <h4>Aktive Fahrer in der Heide: {this.state.currentTripsCount}</h4>
          {
            this.state.sunriseToday 
            && moment().isBetween(oneOurAfter, twoHoursBefore) 
            && !this.state.isLoading
            ? 
            <h4 className="text-success">Fahrt möglich <small className="text-muted">bis {twoHoursBefore.format("HH:mm")} Uhr!</small></h4> 
            :
            <div>
            <h4 className={this.state.isLoading ? "" : "text-danger"}>
            {this.state.isLoading ? "Daten werden geladen.." : "Fahrt nicht möglich!"}</h4><br/>
            <p className="lead">Erst wieder ab<br/> 
              {moment().isBefore(twoHoursBefore) 
              ? oneOurAfter.format("DD.MM.YY HH:mm") 
              : sunriseTomorrow } Uhr
            </p>
            </div>
          }
          {
            !this.state.showGreeting &&
            !this.state.riding &&
            !this.state.trip_id
            ? 
            <Modal
              disabled={false/*!this.state.sunriseToday || !moment().isBetween(this.state.sunriseToday, twoHoursBefore)*/}
              text={this.state.isLoading ? "Daten werden geladen ..." : "Trip starten"}
              startTrip={this.handleTripStart.bind(this)}
            />
            /*<button
              type="button"
              className="btn btn-success btn-lg btn-block mt-5"
              onClick={this.handleTripStart.bind(this)}
              disabled={!this.state.sunriseToday || !moment().isBetween(this.state.sunriseToday, twoHoursBefore)}
            >{this.state.isLoading ? "Daten werden geladen ..." : "Trip starten"}</button>*/
            :
            <div>
              <h3 className={ "mt-5 text-center " + (!this.state.errorMessage ? "text-success" : "text-danger")}>
                { !this.state.errorMessage ? "Gute Fahrt!" : "Etwas ist schief gelaufen! Versuche es erneut."}
              </h3>
              <button
                type="button"
                className="btn btn-danger btn-lg btn-block mt-5"
                onClick={this.handleTripEnd.bind(this)}
              >{this.state.isLoading ? "Daten werden geladen ..." : "Trip beenden"}</button>
            </div>
          }
        </div>
        }
      </div>
    )
  }
}