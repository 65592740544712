import React, { Component } from 'react';
import { default as ReactModal } from 'react-modal';

class Modal extends Component {
    state = {
        carriage_id: "",
        modalIsOpen: false
    }
    
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
      }

    handleChange(e) {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }


    render() {
        return (
        <div>
            <button disabled={this.props.disabled} type="button" className="btn btn-lg btn-block mt-5" onClick={() => this.setState({ modalIsOpen: true })}>
                    {this.props.text}
            </button>
        
            <ReactModal
                ariaHideApp={false}
                isOpen={this.state.modalIsOpen}
                onRequestClose={() => {
                    this.setState({
                        modalIsOpen: false
                    });
                }}
                contentLabel="Example Modal"
            >
                <form onSubmit={e => { e.preventDefault(); this.setState({ modalIsOpen: false }); this.props.startTrip(this.state.carriage_id); }}>
                    <div className="form-group">
                        <label htmlFor="recipient-name" className="col-form-label">Bitte Kutschennummer eintragen:</label>
                        <input name="carriage_id" type="text" className="form-control" onChange={this.handleChange} value={this.state.carriage_id} />
                    </div>
                    <div className="form-group text-right">
                        <input type="submit" value="Absenden" className="btn btn-success" />
                    </div>
                </form>
            </ReactModal>
        </div>
        )
    }
}

export default Modal;