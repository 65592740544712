import React, { Component } from 'react';
import axios from 'axios';

class AddAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            firstname: "",
            lastname: ""
        };
    }

    handleChange(e) {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }

    handleFormSubmit(e) {
        e.preventDefault();
        console.log(this.state.firstname, this.state.lastname, this.state.email);
        axios.post('https://kutschenapi.h4.ddnss.org/users',

            {
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                email: this.state.email
            },
            {
                auth: {
                    username: localStorage.getItem("id_token"),
                    password: "unused"
                }
            }).then(res => {
                axios.post('https://kutschenapi.h4.ddnss.org/accounts',
                    {
                        username: this.state.email,
                        password: this.state.password,
                        user_id: res.data.id,
                        userlevel: "standard"
                    },
                    {
                        auth: {
                            username: localStorage.getItem("id_token"),
                            password: "unused"
                        }
                    }).catch(err => {
                        console.log(err);
                    })
            }).catch(err => {
                console.log(err);
            })
        this.setState({
            email: "",
            password: "",
            firstname: "",
            lastname: ""
        })
        this.props.history.replace('/dashboard')
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="card border-primary">
                            <div className="card-header">Login</div>
                            <div className="card-body text-left">
                                <div className="form-group">
                                    <form onSubmit={this.handleFormSubmit.bind(this)}>
                                        <label htmlFor="exampleInputEmail1">Email:</label>
                                        <input
                                            name="email"
                                            type="email"
                                            className="form-control"
                                            value={this.state.email}
                                            onChange={this.handleChange.bind(this)}
                                        />
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Passwort:</label>
                                            <input
                                                type="password"
                                                name="password"
                                                className="form-control"
                                                value={this.state.password}
                                                onChange={this.handleChange.bind(this)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Vorname:</label>
                                            <input
                                                type="text"
                                                name="firstname"
                                                className="form-control"
                                                value={this.state.firstname}
                                                onChange={this.handleChange.bind(this)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Nachname:</label>
                                            <input
                                                type="text"
                                                name="lastname"
                                                className="form-control"
                                                value={this.state.lastname}
                                                onChange={this.handleChange.bind(this)}
                                            />
                                        </div>
                                        <input
                                            value="hinzufügen"
                                            type="submit"
                                            className="btn btn-success"
                                        />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddAccount;