import React, {Component} from 'react';
import Axios from 'axios';
import withAuth from '../components/withAuth'
import moment from 'moment-timezone'
import { Table } from 'reactstrap';

class Fahrtenbuch extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          trips: ""
        };
      }

    componentDidMount() {
        this.setState({
            isLoading: true
        });

        Axios.get('https://kutschenapi.h4.ddnss.org/all-trips', {
            auth: {
                username: localStorage.getItem("id_token"),
                password: "unused"
            }
        })
        .then( res => {
            this.setState({
                trips: res.data,
                isLoading: false
            });
        })
    }

    render() {
        let content;
        if(this.state.isLoading) {
            content = <div>Daten werden geladen ...</div>;
        } else {
            content = (
                <Table striped>
                    <thead>
                    <tr>
                        <th>Kutschen Nr.</th>
                        <th>Einfahrtszeit</th>
                        <th>Endzeit</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.trips && this.state.trips.length > 0 ? this.state.trips.map(trip => {
                            return (
                                <tr key={"trip_" + trip.id}>
                                    <td>{trip.carriage.number}</td>
                                    <td>{moment(trip.entrytime).tz("Europe/Berlin").format("DD.MM.YY HH:mm")}</td>
                                    <td>{moment(trip.exittime).tz("Europe/Berlin").format("DD.MM.YY HH:mm")}</td>
                                </tr>
                            )
                        }) : <tr><td colSpan="4" style={{ padding: '15px', textAlign: 'center' }}>Noch keine Fahrten gemacht</td></tr>}
                    </tbody>
                </Table>
            )
        }

        return(
                <div className="container mt-5">
                    {content}
                </div>
        );
    }
}

export default withAuth(Fahrtenbuch);