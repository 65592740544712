import axios from 'axios';

class AuthService {

    // Initializing important variables
    constructor(domain) {
        this.domain = domain || 'https://kutschenapi.h4.ddnss.org' // API server domain
        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
    }

    login(username, password) {
        // Get a token from api server using the fetch api
        return axios(`${this.domain}/token`, {
            method: 'GET',
            auth: {
                username,
                password
            }
        }).then(res => {
            let exp_time = Date.now();
            exp_time += res.data.duration;
            this.setToken(res.data.token, exp_time); // Setting the token in localStorage
            localStorage.setItem("userlevel", res.data.userlevel);
            return Promise.resolve(res);
        }).catch(error => {
            if (error.response.status === 401) {
                alert("Nutzername oder Passwort falsch.");
            } else if (error.response.status === 500) {
                alert("Interner Server-Fehler. Bitter probieren sie es erneut"); 
            };
        });
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // GEtting token from localstorage
        return !!token && !this.isTokenExpired() // handwaiving here
    }

    isTokenExpired() {
        if ( localStorage.getItem("token_exp") < Date.now() ) {
            return true;
        } return false;
    }

    setToken(idToken, exp_time) {
        // Saves user token to localStorage
        localStorage.setItem('id_token', idToken)
        localStorage.setItem('token_exp', exp_time)
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('id_token')
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem('id_token');
    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        return this.getToken();
    }


    fetch(url, options) {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken()
        }

        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }
}

export default new AuthService();