import React, { Component } from 'react';
import AuthService from './AuthService';

class Login extends Component {
    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    state = {
        username: "",
        password: "",
        isLoading: false
    }
    componentWillMount() {
        this.setState({isLoading: true})
        if (AuthService.loggedIn()) {
            this.props.history.push("/");
        }
    }

    componentDidMount() {
        this.setState({isLoading: false})
    }

    handleChange(e) {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }

    handleFormSubmit(e) {
        e.preventDefault();
        this.setState({isLoading: true})
        localStorage.setItem("username", this.state.username);
        AuthService.login(this.state.username, this.state.password)
            .then(res => {
                this.props.history.replace('/dashboard');
            })
            .catch(err => {
                alert(err);
            })
        this.setState({
            username: "",
            password: "",
        })
    }

    render() {
        return (
            <div>
            {this.state.isLoading 
            ? <div className="center">
                <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              </div>  
            : 
            <div className="container mt-5">
                <div className="row">
                    <div className="col-12">
                        <div className="card border-primary">
                            <div className="card-header">Login</div>
                            <div className="card-body text-left">
                                <div className="form-group">
                                    <form onSubmit={this.handleFormSubmit}>
                                        <label htmlFor="exampleInputEmail1">Username:</label>
                                        <input
                                            name="username"
                                            type="text"
                                            className="form-control"
                                            placeholder="Username"
                                            value={this.state.username}
                                            onChange={this.handleChange}
                                        />
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Passwort:</label>
                                            <input
                                                type="password"
                                                name="password"
                                                className="form-control"
                                                placeholder="Passwort"
                                                value={this.state.password}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <input
                                            value="Anmelden"
                                            type="submit"
                                            className="btn btn-success"
                                        />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            </div>
        );
    }
}

export default Login;