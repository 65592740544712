import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import './index.css'

import AuthService from './components/AuthService';
import Layout from './components/Layout';
import Login from './components/Login'
import { BrowserRouter as Router, Route, withRouter, Switch, Redirect } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import Dashboard from './Dashboard'
import Fahrtenbuch from './components/Fahrtenbuch'
import AddAccount from './components/AddAccount'

class PrivateRouteWrapper extends Component {
    render() {
        if (!AuthService.loggedIn()) {
            return <Redirect to="/login" />
        }

        return (
            <Route {...this.props} />
        )
    }
};

const PrivateRoute = withRouter(PrivateRouteWrapper);

ReactDOM.render(
    <Router>
        <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/" render={() => (<Redirect to="/dashboard"/>)}/>
            <Layout>
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/trips" component={Fahrtenbuch} />
                <PrivateRoute exact path="/addaccount" component={AddAccount} />
            </Layout>
        </Switch>
    </Router>
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
