import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import AuthService from "./AuthService";

class NavBar extends Component {

  state = {
    isAdmin: false
  }

  handleLogout() {
    AuthService.logout()
    this.props.history.replace('/login');
  }

  componentWillMount() {
    const isAdmin = localStorage.getItem("userlevel") === "admin";
    isAdmin && this.setState({ isAdmin: true })
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <Link className="navbar-brand" to="/dashboard">Kutschen-Fahrfreunde</Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <Link className="nav-item nav-link" to="/dashboard" >Home</Link>
            {this.state.isAdmin && <Link className="nav-item nav-link" to="/trips" >Fahrtenbuch</Link>}
            {this.state.isAdmin && <Link className="nav-item nav-link" to="/addaccount" >User hinzufügen</Link>}
            <div className="nav-item nav-link" onClick={this.handleLogout.bind(this)}>Logout</div>
          </div>
        </div>
      </nav>
    );
  }
}

export default withRouter(NavBar);
