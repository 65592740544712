import React, { Component } from 'react';
import NavBar from "./NavBar";

export default class Layout extends Component {
    render() {
        return (
            <div className="page-container" id="page-container">
                <NavBar/>
                {this.props.children}
            </div>
        );
    }
}